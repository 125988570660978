<template>
  <div
    class="form-login d-flex justify-content-center align-items-center flex-column formLogin"
  >
    <div class="logo img-logo-header">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="w-100 mb-3">
      <div class="col-lg-12 col-sm-12" style="">
        <div
          style="
            padding: 5px 15px !important;
            border: 1px solid #c3c4c7;
            border-radius: 8px !important;
          "
        >
          <form v-on:submit.prevent="submitLogin()">
            <div class="form-title">ログイン後コンテンツが表示されます</div>
            <b-alert show variant="danger" v-if="alertMessage">{{
              message
            }}</b-alert>
            <p class="sp-only login-title">メールアドレスでログイン</p>
            <div class="d-flex flex-column form-input">
              <label for="email" style="font-size: 16px; color: black"
                >ユーザー名またはメールアドレス</label
              >
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="メールアドレス"
                style="color: black"
              />
            </div>
            <div class="d-flex flex-column form-input input-group">
              <label
                for="password"
                style="font-size: 16px; vertical-align: middle"
                class="align-items-center d-flex mb-0"
                ><span style="color: black" class="w-100">パスワード</span
                ><router-link
                  :to="{
                    name: $route.params.shopId
                      ? 'forgot password'
                      : 'forgot password domain',
                  }"
                  class="color-blue w-100"
                >
                  <a
                    class="float-right w-100"
                    style="
                      font-size: 12px;
                      text-decoration-line: underline;
                      text-align: right;
                    "
                    >パスワードをお忘れですか？</a
                  >
                </router-link></label
              >
              <div class="row ml-0 mr-0 flex-nowrap">
                <input
                  v-if="isshowPassword === false"
                  v-model="password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="パスワード"
                  class="col-11"
                />
                <input
                  v-else-if="isshowPassword === true"
                  v-model="password"
                  name="password"
                  id="password"
                  placeholder="パスワード"
                  class="col-11"
                />
                <span
                  v-if="isshowPassword === false"
                  class="input-group-text col-auto px-2"
                  id="basic-addon2"
                  style="
                    height: 50px;
                    margin: 5px 0px 20px;
                    cursor: pointer;
                    color: black;
                  "
                  @click="showPassword"
                  ><i class="fas fa-eye" style="width: 17.5px"></i
                ></span>
                <span
                  v-else-if="isshowPassword === true"
                  class="input-group-text col-auto px-2"
                  id="basic-addon2"
                  style="
                    height: 50px;
                    margin: 5px 0px 20px;
                    cursor: pointer;
                    color: black;
                  "
                  @click="showPassword"
                  ><i class="fas fa-eye-slash"></i
                ></span>
              </div>
            </div>
          </form>
          <!-- <span class="title2">他のIDでログイン</span> -->
          <!-- <div class="container login-sns">
            <div class="row">
              <div
                class="col-lg-12 mx-auto"
                style=""
                v-if="isCheckFacebook === 1"
              >
                <a
                  :href="`${urlBackend}/auth/facebook?shop_id=${shop_id}&slag_token=${slagToken}&isLogin=${1}`"
                >
                  <button class="btn-common btn-facebook">
                    <i class="fab fa-facebook-square size-icon"></i>
                    Facebook でログインする
                  </button>
                </a>
              </div>
              <div class="col-lg-12" v-if="isCheckTwitter === 1">
                <a>
                  <button
                    class="btn-common btn-twitter"
                    v-on:click.prevent="loginTwitter()"
                  >
                    <i class="fab fa-twitter size-icon"></i>
                    Twitter でログインする
                  </button>
                </a>
              </div>
              <div class="col-lg-12" v-if="isCheckLine === 1">
                <button
                  class="btn-common btn-line"
                  v-on:click.prevent="loginLine()"
                >
                  <i class="fab fa-line size-icon"></i>
                  <i class="bi bi-activity"></i>
                  Line でログインする
                </button>
              </div>
              <div class="col" v-if="isCheckAmazon === 1">
                <a
                  :href="`${urlBackend}/auth/amazon?shop_id=${shop_id}&slag_token=${slagToken}&isLogin=${1}`"
                >
                  <button class="btn-common btn-amazon">
                    <i class="fab fa-amazon size-icon"></i>
                    Amazon でログインする
                  </button>
                </a>
              </div>
            </div>
          </div> -->
          <div class="remember-form mt-4">
            <input
              v-model="remember_me"
              type="checkbox"
              name="remember"
              id="remember"
            />
            <label class="ml-1" for="remember">ログインしたままにする</label>
          </div>
          <div class="btn-submit mt-3">
            <!-- <button v-on:click.prevent="submitLogin(shopId)" style="width: 50%">
              ログイン
            </button> -->
            <!-- Thinh -->
            <b-button
              v-on:click.prevent="submitLogin()"
              :disabled="isLoading"
              style="width: 50%"
              name="submit_login"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <span>ログイン</span>
            </b-button>
          </div>
          <router-link
            :to="{
              name: $route.params.shopId
                ? 'forgot password'
                : 'forgot password domain',
            }"
            class="color-blue sp-only"
          >
            <a
              class="w-100"
              style="
                font-size: 12px;
                text-decoration-line: underline;
                text-align: center;
              "
              >パスワードをお忘れですか？</a
            >
          </router-link>
        </div>
        <div
          style="padding: 5px 30px !important"
          class="register_pre"
          v-if="
            $route.query.token &&
            $route.query.token !== 'undefined' &&
            $route.query.token !== 'null'
          "
        >
          <div v-if="jlg == false">
            <span class="title2">初めての方ですか？</span>
            <router-link
              v-if="jrg == true"
              :to="{
                name: $route.params.shopId
                  ? 'create member'
                  : 'create member domain',
                query: { token: URLToken, jrg: jrg },
              }"
            >
              <div class="btn-register">
                <button>今すぐ新規会員登録</button>
              </div>
            </router-link>
            <router-link
              v-else
              :to="{
                name: $route.params.shopId
                  ? 'create member'
                  : 'create member domain',
                query: { token: URLToken },
              }"
            >
              <div class="btn-register">
                <button>今すぐ新規会員登録</button>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
// import { Urls } from "../../utils/urls";
import no_image from "@/assets/img/logo_default.png";

export default {
  name: "Login",
  data() {
    return {
      urlBackend: Constants.URL_BE_API,
      email: "",
      password: "",
      remember_me: false,
      shopId: this.$route.params.shopId,
      alertMessage: false,
      message: "",
      shop_id: null,
      logoShop: null,
      UrlRedirect: null,
      dataHTML: [],
      URLToken: null,
      dataActiveToken: null,
      isCheckFacebook: null,
      isCheckLine: null,
      isCheckTwitter: null,
      isCheckAmazon: null,
      slagToken: this.$route.query.token,
      isshowPassword: false,
      isLoading: false,
      jlg: false,
      jrg: this.$route.query.jrg,
      time_start: new Date().getTime(),
      time_spent: 0,
      tokenUser: localStorage.getItem(Constants.TOKEN_USER),
      dataShop: null,
    };
  },
  async created() {
    if (this.$route.query.jlg && this.$route.query.jlg == "true") {
      this.jlg = true;
    }
    let request = {
      domain: Constants.DOMAIN,
      shop_id: this.shopId || null,
    };
    let response = await this.getShopById(request);
    this.shop_id = response.data.id;

    // const tokenUser = localStorage.getItem(Constants.TOKEN_USER);
    // if (tokenUser) {
    //   if (this.slagToken) {
    //     const request = {
    //       shop_id: this.shop_id,
    //       slag_token: this.slagToken,
    //       type: 1,
    //     };
    //     Api.userRequestServer
    //       .post(`/${Urls.SLAG_TOKEN}`, request)
    //       .then((response) => {
    //         const { data } = response;
    //         if (data.success) {
    //           if (data.data) {
    //             window.location.href = data.data.url_redirect;
    //           } else if (this.$route.params.shopId) {
    //             this.$router.push({
    //               name: "library",
    //               params: {
    //                 shopId: this.$route.params.shopId,
    //                 message: data.message,
    //               },
    //             });
    //           } else {
    //             this.$router.push({
    //               name: "library domain",
    //             });
    //           }
    //         } else {
    //           this.$store.commit("set", ["message", data.message]);
    //           this.$store.commit("set", ["error", true]);
    //         }
    //       })
    //       // eslint-disable-next-line no-unused-vars
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   } else {
    //     if (
    //       (this.$route.name == "login shop" ||
    //         this.$route.name == "login shop domain") &&
    //       new Date() <=
    //         new Date(localStorage.getItem(Constants.EXPIRES_AT_USER))
    //     ) {
    //       if (!this.$route.query.redirect) {
    //         if (this.$route.params.shopId) {
    //           this.$router.push({
    //             name: "library",
    //             params: { shopId: this.$route.params.shopId },
    //           });
    //         } else {
    //           this.$router.push({
    //             name: "library domain",
    //           });
    //         }
    //       } else {
    //         this.$router.push(this.$route.query.redirect);
    //       }
    //     }
    //   }
    // }

    this.getListSettingShopUser(request);
    const dataRequest = {
      slag_token: this.$route.query.token,
    };
    this.getURLByToken(dataRequest);
    this.getGeneralByIdContent(this.shop_id);
    if (
      localStorage.getItem(Constants.EMAIL_USER) !== null ||
      localStorage.getItem(Constants.EMAIL_USER) !== undefined
    ) {
      this.email = localStorage.getItem(Constants.EMAIL_USER);
    }
    // const { params } = this.$route;
    // this.shopId = params.shopId;
    localStorage.setItem(Constants.SHOP_ID, this.shop_id);
    const token = localStorage.getItem(Constants.TOKEN_USER);
    const userInfo = JSON.parse(
      localStorage.getItem(Constants.NORMAL_USER_INFO)
    );
    const expires_at = localStorage.getItem(Constants.EXPIRES_AT_USER);
    const isRemember = localStorage.getItem(Constants.IS_REMEMBER_USER);
    this.remember_me =
      new Date() <= new Date(expires_at) ? isRemember === "true" : false;
    if (
      token &&
      userInfo.shop_id == this.shop_id &&
      userInfo.user_type == 4 &&
      isRemember === "true" &&
      new Date() <= new Date(expires_at)
    ) {
      this.$router.push({
        name: this.$route.params.shopId ? "library" : "library domain",
        params: { shopName: this.shopId },
      });
    }
  },
  computed: {
    ...mapGetters(["pageId", "ListSettingShop", "URLByToken", "dataGeneral"]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }
    },
    URLByToken() {
      if (this.URLByToken.redirect_url) {
        this.UrlRedirect = this.URLByToken.redirect_url;
      } else if (this.URLByToken.url_redirect_provide) {
        this.UrlRedirect = this.URLByToken.url_redirect_provide;
      }
      if (this.URLByToken.newsletters) {
        this.URLByToken.newsletters.forEach((element) => {
          this.dataHTML.push(element);
        });
      }
      if (this.URLByToken.slag_token) {
        this.URLToken = this.URLByToken.slag_token;
      } else if (this.URLByToken.token) {
        this.URLToken = this.URLByToken.token;
      }
    },
    dataGeneral() {
      if (this.dataGeneral) {
        this.isCheckFacebook = this.dataGeneral.facebook_status;
        this.isCheckLine = this.dataGeneral.line_status;
        this.isCheckTwitter = this.dataGeneral.twitter_status;
        this.isCheckAmazon = this.dataGeneral.amazone_status;
      }
    },
  },
  methods: {
    ...mapActions({
      setPageId: "setPageId",
      getListSettingShopUser: "getListSettingShopUser",
      getURLByToken: "getURLByToken",
      getGeneralByIdContent: "getGeneralByIdContent",
      getShopById: "getShopById",
    }),
    setAlert() {
      this.alertMessage = true;
    },
    submitLogin() {
      if (!this.email && !this.password) return;
      this.email = this.email ? this.email.trim() : "";
      this.password = this.password ? this.password.trim() : "";
      this.isLoading = true;
      const formLogin = {
        email: this.email,
        password: this.password,
        shop_id: this.shop_id,
        remember_me: this.remember_me,
        slag_token: this.$route.query.token,
        title: this.$route.meta.title,
        referrer_url: window.location.origin + this.$route.path,
        url:
          window.location.origin +
          this.$router.resolve({
            name: this.shopId ? "library" : "library domain",
          }).href,
        id_button: window.document.activeElement.name,
      };
      this.time_spent = (new Date().getTime() - this.time_start) / 1000;
      formLogin.time_spent_on_page = this.time_spent;
      this.time_start = new Date().getTime();
      if (this.password !== "" && this.email !== "") {
        if (location.hostname !== Constants.DOMAIN_DEFAULT) {
          Api.userRequestServer
            .post(`/login`, formLogin)
            .then((response) => {
              const { data } = response;
              if (data.success) {
                this.loginDefaultDomain(formLogin, this.shop_id);
              } else {
                this.message = data.message;
                this.setAlert();
                setTimeout(() => (this.alertMessage = false), 5000);
              }
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
            });
        } else {
          this.loginDefaultDomain(formLogin, this.shop_id);
        }
      } else {
        this.message = "メールアドレスまたはパスワードを入力してください";
        this.setAlert();
        setTimeout(() => (this.alertMessage = false), 5000);
        this.isLoading = false;
      }
      // this.isLoading = false;
    },
    loginDefaultDomain(formLogin, idShop) {
      Api.userRequestServer
        .post(`/login`, formLogin)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            const { access_token, user, expires_in } = data.data;
            const { user_type } = user;
            const expires_at = new Date(
              new Date().setSeconds(new Date().getSeconds() + expires_in)
            );
            if (user_type != 4) {
              this.message =
                "メールアドレスまたはパスワードが正しくありません。";
              this.setAlert();
              this.isLoading = false;
            } else {
              localStorage.setItem(Constants.TOKEN_USER, access_token);
              localStorage.setItem(Constants.USER_TYPE_USER, user_type);
              localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
              localStorage.setItem(Constants.USER_ID, data.data.user.user_id);
              localStorage.setItem(Constants.EMAIL_USER, data.data.user.email);
              localStorage.setItem("popupSend", 1);
              localStorage.setItem(
                Constants.NORMAL_USER_INFO,
                JSON.stringify(user)
              );
              localStorage.setItem(
                Constants.IS_REMEMBER_USER,
                this.remember_me
              );
              if (!this.$route.query.redirect) {
                if (this.UrlRedirect) {
                  if (this.UrlRedirect) {
                    window.location.href = this.UrlRedirect;
                  } else {
                    this.$router.push({
                      name: this.$route.params.shopId
                        ? "library"
                        : "library domain",
                      params: {
                        shopId: this.$route.params.shopId ? this.shopId : "",
                      },
                    });
                  }
                } else {
                  this.$router.push({
                    name: this.$route.params.shopId
                      ? "library"
                      : "library domain",
                    params: { shopId: this.$route.params.shopId ? idShop : "" },
                  });
                }
              } else {
                this.$router.push(this.$route.query.redirect);
              }
            }
          } else {
            this.message = data.message;
            this.setAlert();
            setTimeout(() => (this.alertMessage = false), 5000);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    loginFacebook() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/facebook?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginTwitter() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/twitter?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginLine() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/line?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginAmazon() {
      if (this.$route.query.token) {
        this.slagToken = this.$route.query.token;
      }
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/amazon?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showPassword() {
      this.isshowPassword = !this.isshowPassword;
    },
  },
};
</script>
<style lang="scss" scoped>
.formLogin {
  width: 540px !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 420px) {
    width: 100% !important;
  }
  /* Fix bug login don't show background */
  @media screen and (max-width: 767px) {
    width: 80% !important;
  }
  @media screen and (min-width: 768px) {
    width: 540px !important;
  }
  @media screen and (min-width: 540px) {
    width: 520px !important;
  }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    width: 540px !important;
  }
}

.img-logo-header {
  width: 286px;
  height: 79px;
  @media (max-width: 450px) {
    width: auto !important;
    height: 60px !important;
    margin-bottom: 10px !important;
  }
}
.customTitleContent {
  color: #3c434a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: bold;
  font-size: 18px;
}
.customAmountContent {
  color: #900;
  font-weight: bold;
  font-size: 23px;
  text-align: right;
  padding: 7px 0;
}
.totalAmount {
  float: right;
  text-align: right;
  color: #d45452;
  font-size: 23px;
  font-weight: bold;
}
.title2 {
  color: #72777c;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 2em !important;
  margin-bottom: 1em !important;
  display: flex;
  align-items: center;
}
.title2::before,
.title2::after {
  border-top: 2px solid;
  content: "";
  flex-grow: 1;
}
.title2:before {
  margin-right: 0.5rem;
}
.title2:after {
  margin-left: 0.5rem;
}
</style>
